<template>
    <div class="bg-info p-2 mt-2">
        <div class="d-flex justify-content-between align-items-center mb-2">
            <h6 class="text-white font-weight-bold m-0">Variações {{variacoesProduto.length > 0 ? `(${variacoesProduto.length})` : ''}}</h6>
            <button class="btn btn-sm btn-outline-light" v-if="edicaoHabilitada" @click="addVariacaoProduto">+</button>
        </div>
        <b-table-lite class="bg-white"
                      :fields="itemsTabela"
                      :items="variacoesProduto">

            <template #cell(images)="{ item }">
                <img :src="item.images[0]"
                     width="60" />
            </template>

            <template #cell(price)="{ item }">
                R$ {{ item.specifications.find(x => x.key === 'price').value }}
            </template>

            <template #cell(promotional_price)="{ item }">
                R$ {{ item.specifications.find(x => x.key === 'promotional_price').value }}
            </template>

            <template v-for="(variacao, index) in itemsTabela.filter(x => !['images', 'price', 'promotional_price', 'botoes'].includes(x.key))"
                      v-slot:[`cell(${variacao.key})`]="data">
                <p class="m-0" v-if="data.item[variacao.key]">{{data.item[variacao.key]}}</p>
                <p class="m-0" v-if="data.item.specifications.find(x => x.key === variacao.key)">
                    {{ identificarValorSpecificacaoVariacao(data.item.specifications.find(x => x.key === variacao.key)) }}
                </p>
            </template>

            <template #cell(botoes)="data">
                <div class="d-flex justify-content-around align-items-center">
                    <i type="button" class="fas fa-eye" @click="toggleDetails(data)"></i>
                    <i type="button" class="fas fa-trash"></i>
                </div>
            </template>

            <template #row-details="data">
                <b-form id="form-variacao-produto" class="formulario-edicao-variacao-produto d-flex">
                    <div class="w-50 pr-5">
                        <label class="d-flex justify-content-between">
                            <span>SKU</span>
                            <input type="text" v-model="data.item.sku" :disabled="!edicaoHabilitada" />
                        </label>

                        <label class="d-flex justify-content-between">
                            <span>EAN</span>
                            <input type="text" v-model="data.item.ean" :disabled="!edicaoHabilitada" />
                        </label>

                        <label class="d-flex justify-content-between">
                            <span>Qtde</span>
                            <input type="number" v-model="data.item.qty" :disabled="!edicaoHabilitada" />
                        </label>

                        <label class="d-flex justify-content-between">
                            <span>Preço</span>
                            <input v-model.lazy="data.item.specifications.find(x => x.key === 'price').value"
                                   v-money="{thousands: '.', decimal: ',', precision: 2}"
                                   :disabled="!edicaoHabilitada" />
                        </label>

                        <label class="d-flex justify-content-between">
                            <span>Preço Promocional</span>
                            <input v-model.lazy="data.item.specifications.find(x => x.key === 'promotional_price').value"
                                   v-money="{thousands: '.', decimal: ',', precision: 2}"
                                   :disabled="!edicaoHabilitada" />
                        </label>

                        <div class="variacao-produto-imagens d-flex justify-content-between mt-2">
                            <label>
                                Imagens
                            </label>
                            <div class="caixa-imagens-variacao-produto border p-2">
                                <div class="d-flex align-items-center h-100">
                                    <div class="d-flex flex-column align-items-center justify-content-around mr-2"
                                         v-for="(image, index) in data.item.images">

                                        <i type="button"
                                           class="fas fa-trash fa-xs mb-1"
                                           v-if="edicaoHabilitada"
                                           @click="data.item.images.splice(index, 1)"></i>

                                        <img :src="image"
                                             :style="{ opacity: image.match('blob') ? '.5' : '' }"
                                             width="75" />
                                    </div>
                                    <p v-if="!data.item.images.length && !edicaoHabilitada" class="mb-4">Nenhuma imagem encontrada</p>

                                    <label v-if="edicaoHabilitada" class="mb-0" for="file-input-variacoes">
                                        <i type="button"
                                           class="fas fa-plus-circle fa-2x mx-2"></i>
                                        <input id="file-input-variacoes"
                                               type="file"
                                               accept="image/*"
                                               multiple
                                               class="d-none"
                                               @change="mostrarNovasImagensVariacao($event, data.index)" />
                                    </label>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="w-50 overflow-auto">
                        <div class="d-flex align-items-center mb-3">
                            <h5 class="text-secondary m-0">Especificações</h5>
                            <button type="button"
                                    class="btn btn-sm btn-outline-info ml-2"
                                    v-if="edicaoHabilitada"
                                    @click="addSpecificacaoVariacao(data.index)">
                                +
                            </button>
                        </div>

                        <div id="especificacoes-variacao"
                             v-for="spec in data.item.specifications.filter(spec => spec.key !== 'price' && spec.key !== 'promotional_price')">
                            <div class="d-flex justify-content-between mb-2">
                                <input placeholder="Chave"
                                       class="input-key-specification"
                                       list="atributos-variacoes"
                                       v-model="spec.key"
                                       :disabled="!edicaoHabilitada" />
                                <datalist id="atributos-variacoes">
                                    <option v-for="atributo in atributos"
                                            :key="atributo.name"
                                            :value="atributo.name" />
                                </datalist>

                                <div class="mx-1"></div>

                                <input type="text" v-model="spec.value" :disabled="!edicaoHabilitada" />
                            </div>
                        </div>
                    </div>

                </b-form>

                <div v-if="edicaoHabilitada && !data.item.novaVariacao" class="w-100 text-right">
                    <button type="button"
                            class="btn btn-sm btn-success mb-2"
                            @click="salvarVariacao(data)">
                        Salvar
                    </button>
                </div>

            </template>

        </b-table-lite>
    </div>
</template>

<script>
    import { VMoney } from 'v-money'

    export default {
        name: 'TabelaVariacoesProduto',
        directives: { money: VMoney },
        props: {
            variacoesProduto: {
                type: Array
            },
            itemsTabela: {
                type: Array
            },
            edicaoHabilitada: {
                type: Boolean
            },
            atributos: {
                type: Array
            }
        },
        methods: {
            identificarValorSpecificacaoVariacao: (objSpecificacao) => objSpecificacao?.value,
            toggleDetails(row) {
                let variacao = row.item
                if (variacao._showDetails) {
                    this.$set(variacao, '_showDetails', false)
                } else {
                    this.variacoesProduto.forEach(item => {
                        this.$set(item, '_showDetails', false)
                    })

                    this.$nextTick(() => {
                        this.removerSpecificacoesVazias(row.index);
                        console.log(variacao.specifications)
                        variacao.specifications = this.formatarPrecosVariacao(variacao.specifications, true);
                        this.$set(variacao, '_showDetails', true)
                    })
                }
            },
            addVariacaoProduto() {
                this.variacoesProduto.push({
                    novaVariacao: true,
                    sku: '',
                    ean: '',
                    qty: 0,
                    images: [],
                    specifications: [
                        { key: 'price', value: 0 },
                        { key: 'promotional_price', value: 0 },
                    ]
                })

                this.toggleDetails({
                    index: this.variacoesProduto.length - 1,
                    item: this.variacoesProduto[this.variacoesProduto.length - 1]
                });
            },
            mostrarNovasImagensVariacao(event, indiceVariacao) {
                let imagens = event.target.files

                if (imagens.length > 0) {
                    if (!this.variacoesProduto[indiceVariacao].imagensAdicionadas)
                        this.variacoesProduto[indiceVariacao].imagensAdicionadas = [];

                    for (let img of imagens) {
                        if (img.type.split('/')[0] === 'image') {
                            let imagemUrl = URL.createObjectURL(img);
                            this.variacoesProduto[indiceVariacao].images.push(imagemUrl);
                            this.variacoesProduto[indiceVariacao].imagensAdicionadas.push({ arquivo: img, urlBlob: imagemUrl })
                        }
                    }
                }
            },
            addSpecificacaoVariacao(indiceVariacao) {
                this.variacoesProduto[indiceVariacao].specifications.push({ key: '', value: '' });
            },
            removerSpecificacoesVazias(indiceVariacao) {
                this.variacoesProduto[indiceVariacao].specifications
                    = this.variacoesProduto[indiceVariacao].specifications.filter(spec => spec.key);
            },
            formatarPrecosVariacao(specifications, formatoApresentacao = false) {
                return specifications.map(spec => {
                    if (['price', 'promotional_price'].includes(spec.key)) {
                        console.log(spec);
                        return {
                            ...spec,
                            value: formatoApresentacao ?
                                this.formataPrecoReais(spec.value)
                                : spec.value.replace(/[.]/g, '').replace(',', '.')
                        }
                    }
                    return spec;
                })
            },
            formataPrecoReais(preco) {
                if (!preco)
                    return preco;

                preco = preco?.replace(',', '.')?.replace(/[.](?=.*[.])/g, "");
                return preco?.split('.')[1].length === 1 ? preco + '0' : preco
            },
            salvarVariacao(data) {
                let variacaoProduto = data.item

                if (variacaoProduto.imagensAdicionadas) {
                    variacaoProduto.imagensAdicionadas = variacaoProduto.imagensAdicionadas
                            .filter(img => variacaoProduto.images.includes(img.urlBlob))
                }

                let variacao = { ...variacaoProduto, specifications: this.formatarPrecosVariacao(variacaoProduto.specifications) }

                this.removerSpecificacoesVazias(data.index);

                this.$emit('salvarVariacao', variacao)
            }
        },
        mounted() {
            this.variacoesProduto.forEach(variacao => {
                if (!variacao.skuInicial) {
                    variacao.skuInicial = variacao.sku;
                }
            })
        }
    }
</script>

<style scoped>
    .formulario-edicao-variacao-produto input, .caixa-imagens-variacao-produto {
        width: 22vw;
    }

    .caixa-imagens-variacao-produto {
        min-height: 90px;
        overflow-y: hidden;
        overflow-x: scroll;
    }

    .input-key-specification {
        border: none;
        border-bottom: 1px solid;
    }

        .input-key-specification:disabled {
            background-color: transparent;
            border: none;
            border-bottom: 1px solid;
        }
</style>
